import { PROGRESS_INTERVAL_TIME_MS } from "./config";
import { store } from "./state";
import { onError, resetInfo } from "./ui";
import { debugLog, show } from "./utils";

export let progressCheckingInterval = null;

export function submitForm(formData) {
  /**
   * @summary Send form data to the server in order to launch a request.
   */
  debugLog("submitForm", formData);

  fetch("/api/submit", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      response
        .json()
        .then((json) => {
          onRequestSubmitted(json);
        })
        .catch((error) => {
          console.log("response", response);
          onError(error);
        });
    })
    .catch((error) => {
      onError(error);
    });
}

function onRequestSubmitted(response) {
  /**
   * @summary handler for the server's response to submitForm
   */
  debugLog("onRequestSubmitted", response);

  if (response.action == "rejected") {
    // Display rejection message

    if (response.reason == "queue_full") {
      console.error(
        "Your request was rejected because the queue is already full. Try again in a moment."
      );
      onError(
        "Votre requête a été rejetée car la queue est pleine. Veuillez réessayer plus tard."
      );
    } else if (response.reason == "error") {
      onError(response.error);
    } else {
      onError("Votre requête a été rejetée. Veuillez réessayer plus tard.");
    }
  } else if (response.action == "realtime") {
    // Update progress
    store.update("currentProgress", {
      pages_to_check: response.pages_to_check,
      queue_position: response.queue_position,
    });

    // Start polling the server periodically about the progress
    checkProgress(response.task_id);
    progressCheckingInterval = setInterval(
      () => checkProgress(response.task_id),
      PROGRESS_INTERVAL_TIME_MS
    );
  } else if (response.action == "batch") {
    // Display that the request was queued
    resetInfo();
    show(document.getElementById("added-to-queue"));
    // Enable the submit button again
    document.getElementById("submit-btn").removeAttribute("disabled");
  } else {
    console.error(`Unexpected action: ${response.action}`);
  }
}

function checkProgress(taskId) {
  /**
   * @summary Send a request to the server for an update on the progress of
   * the checker. The server will send back an "update" event.
   */
  fetch(`/api/status/${taskId}`)
    .then((response) => {
      response
        .json()
        .then((updateData) => {
          debugLog("checkProgress", updateData);
          updateProgressData(updateData);
          onProgressReceived(taskId, updateData.task_state);
        })
        .catch((error) => {
          console.log("response", response);
          onError(error);
        });
    })
    .catch((error) => {
      onError(error);
    });
}

function onProgressReceived(taskId, taskState) {
  if (taskState === "FAILURE") {
    fetch(`/api/results/${taskId}`)
      .then((response) => {
        // If see that the task failed, get the error message from the server
        response
          .json()
          .then((json) => {
            if (json.error) {
              onError(json.error);
              return;
            }
          })
          .catch((error) => {
            onError(error);
            return;
          });
      })
      .catch((error) => {
        onError(error);
        return;
      });

    onError(
      "Une erreur est survenue. Veuillez vérifier votre requête ou réessayer plus tard"
    );
  } else if (taskState === "SUCCESS") {
    // if the server indicates that the request is done being processed,
    // ask the server for the results, and keep asking until we have
    // successfully received them.
    requestResults(taskId);
  }
}

function requestResults(taskId) {
  fetch(`/api/results/${taskId}`)
    .then((response) => {
      response
        .json()
        .then((json) => {
          debugLog("requestResults", json);
          store.update("currentResults", json);
          // Stop requesting updates
          clearInterval(progressCheckingInterval);
        })
        .catch((error) => {
          console.log("response", response);
          onError(error);
        });
    })
    .catch((error) => {
      onError(error);
    });
}

function updateProgressData(data) {
  // Adds new updateData to our currentProgress, because the server
  // doesn't remember past updates sent to us, only the current state of the
  // task.
  const currentProgress = { ...store.state.currentProgress };

  if (data.task_state === "SUCCESS") {
    // If the job is finished, we can update the progress to show completion
    currentProgress.checked_urls = currentProgress.urls_to_check;
    currentProgress.estimated_time_remaining = 0;
  }
  if (data.queue_position != null) {
    currentProgress.queue_position = data.queue_position;
  }
  if (data.pages_to_check) {
    currentProgress.pages_to_check = data.pages_to_check;
  }
  if (data.urls_to_check) {
    currentProgress.urls_to_check = data.urls_to_check;
  }
  if (data.skipped_urls) {
    currentProgress.skipped_urls = data.skipped_urls;
  }
  if (data.checked_urls) {
    currentProgress.checked_urls = data.checked_urls;
  }
  if (data.estimated_time_remaining) {
    currentProgress.estimated_time_remaining = data.estimated_time_remaining;
  }
  if (data.link_checking_start_time) {
    currentProgress.link_checking_start_time = data.link_checking_start_time;
  }
  if (data.finish_time) {
    currentProgress.finish_time = data.finish_time;
    currentProgress.completion_time = Math.round(
      currentProgress.finish_time - currentProgress.link_checking_start_time
    );
  }
  if (data.links_per_second) {
    currentProgress.links_per_second = data.links_per_second;
  }

  // Update the store with the new progress data
  store.update("currentProgress", currentProgress);
}
