import { progressCheckingInterval } from "./form";
import { appState, updateProgressData } from "./state";
import { handleResults, updateUiProgress, onError } from "./ui";
import { show, hide } from "./utils"


export function submitForm(formData, onRequestSubmitted) {
    /**
     * @summary Send form data to the server in order to launch a request. 
     */
    fetch('/api/submit', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    })
        .then(response => {
            response.json()
                .then(json => {
                    onRequestSubmitted(json);
                })
        })
        .catch(error => {
            onError(error);
        })
}


export function checkProgress(taskId) {
    /**
     * @summary Send a request to the server for an update on the progress of
     * the checker. The server will send back an "update" event.
     */
    fetch(`/api/status/${taskId}`)
        .then(response => {
            response.json()
                .then(updateData => {
                    updateProgressData(updateData)
                    console.log(updateData, appState.currentProgress);

                    onProgressReceived(taskId, updateData);
                })
        })
        .catch(error => {
            onError(error);
        })
}


function onProgressReceived(taskId, updateData) {
    updateUiProgress(updateData);


    if (updateData.job_finished) {
        // if the server indicates that the request is done being processed,
        // ask the server for the results, and keep asking until we have
        // successfully received them.
        requestResults(taskId);
    }
}


function requestResults(taskId) {
    fetch(`/api/results/${taskId}`)
        .then(response => {
            response.json()
                .then(json => {
                    //console.log(json);
                    handleResults(json)
                })
        })
        .catch(error => {
            onError(error)
        });
}
