import { debugLog } from "./utils";

class Observable {
  constructor(initialValue) {
    this._value = initialValue;
    this._subscribers = new Set();
  }

  get value() {
    return this._value;
  }

  set value(newValue) {
    this._value = newValue;
    this._notify();
  }

  subscribe(callback) {
    this._subscribers.add(callback);
    // Initial call
    callback(this._value);
    // Return unsubscribe function
    return () => this._subscribers.delete(callback);
  }

  _notify() {
    debugLog("State update", this._value);
    this._subscribers.forEach((callback) => callback(this._value));
  }
}

export function createStore(initialState) {
  const state = {};
  const subscribers = new Set();

  // Create observables for each top-level property
  Object.keys(initialState).forEach((key) => {
    state[key] = new Observable(initialState[key]);
  });

  return {
    get state() {
      return Object.fromEntries(
        Object.entries(state).map(([k, v]) => [k, v.value])
      );
    },

    subscribe(key, callback) {
      if (state[key]) {
        return state[key].subscribe(callback);
      }
      throw new Error(`Unknown state key: ${key}`);
    },

    update(key, value) {
      if (state[key]) {
        state[key].value = value;
      }
    },
  };
}
