import { show, hide, generate_xslx, rewriteRelativeUrl } from './utils'
import { progressCheckingInterval } from './form'
import { appState } from './state';
import { PROGRESS_INTERVAL_TIME_MS } from './config';


let progressBarFinished = false;

export function initUi() {
    document.getElementById("btn-xlsx").addEventListener("click", () => generate_xslx(appState.currentResults));
}

export function updateUiProgress(data) {

    if (appState.currentProgress.queue_position != null) {
        document.getElementById('queue-position').innerHTML = data.queue_position;

        hide(document.getElementById("gathering-pages"));
        if (appState.currentProgress.queue_position > 0) {
            show(document.getElementById("queue-info"));
        } else {
            hide(document.getElementById("queue-info"));
            show(document.getElementById("gathering-links"))
        }
    }

    // display the updated data
    if (appState.currentProgress.pages_to_check) {
        document.getElementById("pages-to-check").innerHTML = appState.currentProgress.pages_to_check;
    }

    if (appState.currentProgress.urls_to_check) {
        document.getElementById("urls-to-check").innerHTML = appState.currentProgress.urls_to_check;

        // Hide preprogress spinner and show progress bar
        show(document.getElementById("progress-update"));
        hide(document.getElementById("preprogress-animation"));
    }
    if (appState.currentProgress.skipped_urls) {
        document.getElementById("skipped-urls").innerHTML = appState.currentProgress.skipped_urls;
    }
    if (appState.currentProgress.checked_urls) {
        document.getElementById("checked-urls").innerHTML = appState.currentProgress.checked_urls;
    }
    if (appState.currentProgress.estimated_time_remaining) {
        document.getElementById("estimated_time_remaining").innerHTML = Math.round(appState.currentProgress.estimated_time_remaining);
        show(document.getElementById("time-left"));
    }
    if (appState.currentProgress.completion_time) {
        document.getElementById("completion-time").innerHTML = Math.round(appState.currentProgress.completion_time);
    }
    if (appState.currentProgress.links_per_second) {
        document.getElementById("links-per-second").innerHTML = appState.currentProgress.links_per_second
    }
    if (appState.currentProgress.checked_urls && appState.currentProgress.urls_to_check){
        setProgressBar(appState.currentProgress.checked_urls / appState.currentProgress.urls_to_check);
    }

}


function setProgressBar(percentage) {
    /** 
     * @summary Sets the progress bar to a certain percentage. Uses GSAP.
     * @see https://codepen.io/alvarotrigo/pen/vYeNpjj
     * @param {float} percentage - a value between 0 and 1
     */
    if (progressBarFinished) return;


    const progressBarContainer = document.querySelector(".custom-progress-bar__container");
    const progressBar = document.querySelector(".custom-progress-bar");
    const progressBarText = document.querySelector(".custom-progress-bar__text");

    percentage = Math.floor(percentage * 100)
    if (percentage == 100) {
        gsap.to(progressBar, {
            x: `${percentage}%`,
            duration: PROGRESS_INTERVAL_TIME_MS / 1000,
            backgroundColor: "var(--primary)",
            onComplete: () => {
                progressBarText.style.display = "initial";
                progressBarContainer.style.boxShadow = "0 0 5px var(--primary-hover)";
            }
        });
        progressBarFinished = true;
    } else {
        gsap.to(progressBar, {
            x: `${percentage}%`,
            duration: PROGRESS_INTERVAL_TIME_MS / 1000,
        });
    }
}

function resetProgressBar() {
    /** 
     * @summary Resets the progress bar. Uses GSAP
     */

    const progressBarContainer = document.querySelector(".custom-progress-bar__container");
    const progressBar = document.querySelector(".custom-progress-bar");
    const progressBarText = document.querySelector(".custom-progress-bar__text");

    progressBarFinished = false;
    progressBarText.style.display = "none";
    progressBarContainer.style.boxShadow = "0 0 5px #516fe7;";
    gsap.to(progressBar, {
        x: `0%`,
        duration: 2,
    });
}

export function resetInfo() {
    /**
     * @summary Hide the results, progress and error elements, basically to
     * start with a blank slate again.
     */
    // Hide results and errors
    hide(document.getElementById("results"));
    hide(document.getElementById("added-to-queue"));
    hide(document.getElementById("queue-info"));
    hide(document.getElementById("gathering-links"));
    show(document.getElementById("gathering-pages"));

    document.getElementById("submit-btn").setAttribute("disabled", true);
    document.getElementById("error-msg").innerHTML = "";
    hide(document.getElementById("no-broken-links"));

    // Reset progress display
    hide(document.getElementById("progress-update"));
    hide(document.getElementById("progress-container"));
    hide(document.getElementById("preprogress-animation"));
    document.getElementById("pages-to-check").innerHTML = "?";
    document.getElementById("urls-to-check").innerHTML = "?";
    document.getElementById("checked-urls").innerHTML = 0;
    document.getElementById("skipped-urls").innerHTML = 0;
    document.getElementById("completion-time").innerHTML = "?";

    resetProgressBar()

    // Remove all rows from the table body
    let tbody = document.querySelector("#results-table tbody");
    while (tbody.firstChild) {
        tbody.removeChild(tbody.lastChild);
    }
}

export function handleResults(results) {
    // Reset form
    document.querySelector('.needs-validation').classList.remove('was-validated');

    // Hide the estimation of the time left, since the task is finished.
    hide(document.getElementById("time-left"));

    // Stop requesting updates
    clearInterval(progressCheckingInterval);

    // // Complete progress
    // setProgressBar(1);
    // document.getElementById('checked-urls').innerHTML = document.getElementById('urls-to-check').innerHTML;

    // Enable submit button again
    document.getElementById("submit-btn").removeAttribute("disabled");
    // Hide the spinner
    hide(document.getElementById("preprogress-animation"))

    // store results (mainly for the XLSX export)
    appState.currentResults = results;

    // if there are no results, show that there were no broken links found
    if (results.length == 0) {
        show(document.getElementById("no-broken-links"));
        return;
    }

    // repopulate table with received data
    let tbody = document.querySelector("#results-table tbody");
    results.forEach((result, index) => {
        // show tooltip for some status codes, since they can
        // result from the checker being blocked by the site, without
        // the link being actually broken
        const blockingCodes = [401, 403, 407, 503]
        let showTooltip = "";
        if (blockingCodes.includes(result.status_code)) {
            showTooltip = `
        <i class="tooltip-icon text-primary bi bi-question-circle" tabindex="0"
            aria-label="Informations" data-toggle="tooltip" data-placement="right"
            title="Un statut ${result.status_code} peut indiquer que le vérificateur a été bloqué pour des raisons d'authentification. Le lien peut toujours être disponible."></i>
        `;
        }

        // create the new row
        let htmlRowContent = `
        <td scope="row" class="align-middle">${index + 1}</td>
        <td class="align-middle"><a href="${result.page_url}">${result.page_title}</a></td>
        <td class="align-middle"><a href="${rewriteRelativeUrl(result.link_url)}">${result.link_title}</a></td>
        <td class="align-middle">${result.link_url}</td>
        <td class="align-middle">${result.status_code}${showTooltip}</td>`;

        // add a new row to the results table
        let row = document.createElement("tr");
        row.innerHTML = htmlRowContent;

        tbody.append(row);
    });

    // initialize all the tooltips that can be found in cells
    $("td i[data-toggle=tooltip]").tooltip();

    // show results table if it was hidden
    show(document.getElementById("results"))
}

export function onError(errorMessage) {
    console.error(errorMessage);

    // stop requesting updates
    clearInterval(progressCheckingInterval);

    // show error message
    const errorMessageContainer = document.getElementById("error-msg")
    errorMessageContainer.innerHTML = "Une erreur s'est produite: " + errorMessage;
    show(errorMessageContainer);

    // enable submit button again
    document.getElementById("submit-btn").removeAttribute("disabled")
    hide(document.getElementById("progress-container"))
}
