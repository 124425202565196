import { appState, updateProgressData } from "./state";
import { handleResults, updateUiProgress, onError } from "./ui";
import { progressCheckingInterval } from "./form";


export function submitForm(formData, onRequestSubmitted) {
  /**
   * @summary Send form data to the server in order to launch a request.
   */
  fetch("/api/submit", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData),
  })
    .then((response) => {
      response.json().then((json) => {
        onRequestSubmitted(json);
      })
      .catch((error) => {
        onError(error);
      });
    })
    .catch((error) => {
      onError(error);
    });
}

export function checkProgress(taskId) {
  /**
   * @summary Send a request to the server for an update on the progress of
   * the checker. The server will send back an "update" event.
   */
  fetch(`/api/status/${taskId}`)
    .then((response) => {
      response.json().then((updateData) => {
        updateProgressData(updateData);
        // Useful debug print to get the raw data from the server
        //console.log(updateData, appState.currentProgress);

        onProgressReceived(taskId, updateData);
      })
      .catch((error) => {
        onError(error);
      });
    })
    .catch((error) => {
      onError(error);
    });
}

function onProgressReceived(taskId, updateData) {
  updateUiProgress(updateData);

  //console.log("onProgressReceived", updateData);

  if (updateData.task_state === "FAILURE") {
    fetch(`/api/results/${taskId}`)
    .then((response) => {
      response.json().then((json) => {
        // Useful debug print to get the raw data from the server
        //console.log(json);
        handleResults(json);
      })
      .catch((error) => {
        onError(error);
      });
    })
    .catch((error) => {
      onError(error);
    });
  
    onError(updateData.results || "Une erreur est survenue. Veuillez réessayer plus tard");

  } else if (updateData.task_state === "SUCCESS") {
    // if the server indicates that the request is done being processed,
    // ask the server for the results, and keep asking until we have
    // successfully received them.
    requestResults(taskId);
  }
}

function requestResults(taskId) {
  fetch(`/api/results/${taskId}`)
    .then((response) => {
      response.json().then((json) => {
        // Useful debug print to get the raw data from the server
        //console.log(json);
        handleResults(json);
      })
      .catch((error) => {
        onError(error);
      });
    })
    .catch((error) => {
      onError(error);
    });
}
