import { appState } from './state';
import { rectifyURL } from './utils';
import { resetInfo, onError } from './ui';
import { show, hide } from './utils';
import { checkProgress, submitForm } from './api';
import { PROGRESS_INTERVAL_TIME_MS } from './config';

let progressCheckingInterval = null;

let pagesToCheck = 0;
let queuePosition = '?';

function initForm() {
    // Validate the form and trigger submitForm when the submit button is clicked
    // see https://getbootstrap.com/docs/5.0/forms/validation/

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    document.querySelector('#submit-btn').addEventListener('click', submit, false)
}

function submit(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = document.querySelector('.needs-validation');

    // Add missing http scheme or www
    const urlTextInput = document.getElementById("url-input");
    urlTextInput.value = rectifyURL(urlTextInput.value);

    validateUrl();
    validateEmail();

    if (!form.checkValidity()) {
        return;
    }

    // Show that the form is valid
    form.classList.add('was-validated');

    // Disable submit button
    document.getElementById("submit-btn").setAttribute("disabled", "");

    // Prepare UI
    resetInfo()

    // Show spinner
    show(document.getElementById("progress-container"));
    show(document.getElementById("preprogress-animation"));
    show(document.getElementById("gathering-pages"));

    // Reset currentProgress
    appState.currentProgress = {};

    // Retrieve form data
    let formData = getFormData();

    // Send form data to the server
    submitForm(formData, onRequestSubmitted);

}
function validateUrl() {
    const input = document.getElementById("url-input");
    const validityState = input.validity;

    // Show an appropriately localized descriptive error tooltip
    if (validityState.valueMissing || validityState.patternMismatch) {
        input.setCustomValidity("Veuillez entrer l'adresse d'un site UNIL");
    } else {
        input.setCustomValidity("");
    }

    input.reportValidity();
}

function validateEmail() {
    const input = document.getElementById("email-input");
    const validityState = input.validity;

    // Show an appropriately localized descriptive error tooltip
    if (validityState.valueMissing || validityState.patternMismatch) {
        input.setCustomValidity("Veuillez entrer une adresse email UNIL");
    } else {
        input.setCustomValidity("");
    }

    input.reportValidity();
}

function getFormData() {
    // Retrieve the data entered in the form and rectify it if needed
    const url = document.getElementById("url-input").value.trim();
    const email = document.getElementById("email-input").value.trim();

    // Get options
    const selectedVerificationScope = document.querySelector(`input[name='verification-scope']:checked`);
    const verificationScope = selectedVerificationScope.value;

    const selectedLinkType = document.querySelector(`input[name='links-type']:checked`);
    const checkScope = selectedLinkType.value;
    const checkInternal = checkScope.includes('internal');
    const checkExternal = checkScope.includes('external');

    const checkMedia = document.getElementById("check-media").checked;
    const flagRedirections = document.getElementById("flag-redirections").checked;

    appState.currentURL = url;

    // Format data as an object for serialization
    const formData = {
        "url": url,
        "email": email,
        "verification-scope": verificationScope,
        "options": {
            "check-external": checkExternal,
            "check-internal": checkInternal,
            "check-media": checkMedia,
            "flag-redirections": flagRedirections
        }
    }

    return formData;
}

function onRequestSubmitted(response) {
    if (response.action == 'rejected') {
        console.log("Your request was rejected because the queue is already full. Try again in a moment.");
        onError("Votre requête a été rejetée car la queue est pleine. Veuillez réessayer plus tard.")
    }
    if (response.action == 'realtime') {
        appState.currentProgress.pages_to_check = response.pages_to_check;
        appState.currentProgress.queue_position = response.queue_position;

        // Start polling the server periodically about the progress
        checkProgress(response.task_id);
        progressCheckingInterval = setInterval(() => checkProgress(response.task_id), PROGRESS_INTERVAL_TIME_MS);

    } else if (response.action == 'batch') {
        resetInfo();
        show(document.getElementById("added-to-queue"));
        // Enable the submit button again
        document.getElementById("submit-btn").removeAttribute("disabled")
    } else {
        console.error(`Unexpected action: ${response.action}`);
    }
}


export { initForm, progressCheckingInterval, onRequestSubmitted }
