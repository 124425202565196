import { appState } from "./state";

const URL_REGEX =
  /^(https?:\/\/)?((?:(?:wp|people|www|wptest|wiki)\.unil\.ch)|(?:www\.asso-unil\.ch))(\/[\-a-zA-Z0-9\(\)@:%_\+.~#?&\/\/=]+?)(\/[\-a-zA-Z0-9\(\)@:%_\+.~#?&\/\/=]+?)?\/?$/;

const show = (element) => element.classList.remove("hidden");
const hide = (element) => element.classList.add("hidden");

function generate_xslx(results) {
  /**
   * @summary Ask the server to generate an excel file with our results and
   * make the client download it
   */
  if (results == null) return;

  // Prepare the data to send to the server
  var requestData = { results: results };

  // Make a POST request to the server to generate the Excel file
  fetch("/api/generate-excel", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Extract filename from response
      const disposition = response.headers.get("Content-Disposition");
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      let filename = "results.xlsx"; // Default filename
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }

      // Save using FileSaver.js
      response.blob().then((blob) => {
        saveAs(blob, filename);
      });
    })
    .catch((error) => {
      console.error("There was a problem with the fetch operation:", error);
    });
}

function dateToString(date) {
  /**
   * @summary Converts a date object to a formatted string
   * @param {Date} date - a date object
   * @returns {string} formatted date string
   * @see https://stackoverflow.com/questions/12409299
   */
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();
  let hh = date.getHours();
  let mn = date.getMinutes();
  //let ss = date.getSeconds();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  if (hh < 10) hh = "0" + hh;
  if (mn < 10) mn = "0" + mn;
  //if (ss < 10) ss = "0" + ss;

  // format: 01-01-1970-00-00
  dateStr = `${yyyy}-${mm}-${dd}-${hh}-${mn}`;

  return dateStr;
}

function rewriteRelativeUrl(url) {
  /**
   * @summary Rewrites the given URL if it's relative, make it absolute
   * @param {String} url the URL to rewrite
   */
  // relative URLs always start with a "/"
  if (!url.startsWith("/")) return url;

  // parse URL
  let match = appState.currentURL.match(URL_REGEX);
  // rewrite URL by adding the http schema and host name
  let rewritten_url = match[1] + match[2] + url;

  return rewritten_url;
}

function rectifyURL(url) {
  /**
   * @summary Check if the HTTP scheme or www is missing from the URL.
   * If at least one is missing, add them to the URL.
   * @param {String} url The URL to rectify
   * @returns {String} the fixed URL
   */
  let fixed_url = url;
  const url_rectification_pattern =
    /^(https?:\/\/(?!www\.))?(www\.)?(asso-unil\.ch|unil\.ch)(\/.*)?$/;
  let match = url.match(url_rectification_pattern);

  if (match) {
    let scheme = match[1] || "https://";
    let www = match[2] || "www.";
    let domain = match[3];
    let path = match[4] || "";

    fixed_url = `${scheme}${www}${domain}${path}`;
  }

  return fixed_url;
}

function formatTimeRemaining(seconds) {
  /**
   * @summary Formats time in seconds into hours, minutes and seconds.
   * @param {number} seconds - Time in seconds
   * @returns {string} Formatted time string
   */
  if (seconds < 60) {
    return `${Math.round(seconds)}s`;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.round(seconds % 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m ${remainingSeconds}s`;
  }
}

export {
  show,
  hide,
  generate_xslx,
  rewriteRelativeUrl,
  rectifyURL,
  dateToString,
  formatTimeRemaining,
};
