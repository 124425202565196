import {
  show,
  hide,
  generate_xslx,
  rewriteRelativeUrl,
  formatTimeRemaining,
} from "./utils";
import { progressCheckingInterval } from "./api";
import { store } from "./state";
import { PROGRESS_INTERVAL_TIME_MS } from "./config";

let progressBarFinished = false;

export function initUi() {
  document
    .getElementById("btn-xlsx")
    .addEventListener("click", () => generate_xslx(store.state.currentResults));

  store.subscribe("currentProgress", updateUiProgress);
  store.subscribe("currentResults", updateUiResults);
}

export function updateUiProgress(data) {
  /**
   * @summary updates the request's progress in the UI
   * @param {Object} data the progress data sent by the server
   */
  if (data.queue_position != null) {
    document.getElementById("queue-position").innerHTML = data.queue_position;

    hide(document.getElementById("gathering-pages"));
    if (data.queue_position > 0) {
      show(document.getElementById("queue-info"));
    } else {
      hide(document.getElementById("queue-info"));
      show(document.getElementById("gathering-links"));
    }
  }

  // display the updated data
  if (data.pages_to_check) {
    document.getElementById("pages-to-check").innerHTML = data.pages_to_check;
  }

  if (data.urls_to_check) {
    document.getElementById("urls-to-check").innerHTML = data.urls_to_check;

    // Hide preprogress spinner and show progress bar
    show(document.getElementById("progress-update"));
    hide(document.getElementById("preprogress-animation"));
  }
  if (data.skipped_urls) {
    document.getElementById("skipped-urls").innerHTML = data.skipped_urls;
  }
  if (data.checked_urls) {
    document.getElementById("checked-urls").innerHTML = data.checked_urls;
  }
  if (data.estimated_time_remaining) {
    document.getElementById("estimated-time-remaining").innerHTML =
      formatTimeRemaining(data.estimated_time_remaining);
    show(document.getElementById("time-left"));
  }
  if (data.completion_time) {
    document.getElementById("completion-time").innerHTML = Math.round(
      data.completion_time
    );
  }
  if (data.links_per_second) {
    document.getElementById("links-per-second").innerHTML =
      data.links_per_second;
  }
  if (data.checked_urls && data.urls_to_check) {
    setProgressBar(data.checked_urls / data.urls_to_check);
  }
}

function setProgressBar(percentage) {
  /**
   * @summary Sets the progress bar to a certain percentage. Uses GSAP.
   * @see https://codepen.io/alvarotrigo/pen/vYeNpjj
   * @param {float} percentage - a value between 0 and 1
   */
  if (progressBarFinished) return;

  const progressBarContainer = document.querySelector(
    ".custom-progress-bar__container"
  );
  const progressBar = document.querySelector(".custom-progress-bar");
  const progressBarText = document.querySelector(".custom-progress-bar__text");

  percentage = Math.floor(percentage * 100);
  if (percentage == 100) {
    gsap.to(progressBar, {
      x: `${percentage}%`,
      duration: PROGRESS_INTERVAL_TIME_MS / 1000,
      backgroundColor: "var(--primary)",
      onComplete: () => {
        progressBarText.style.display = "initial";
        progressBarContainer.style.boxShadow = "0 0 5px var(--primary-hover)";
      },
    });
    progressBarFinished = true;
  } else {
    gsap.to(progressBar, {
      x: `${percentage}%`,
      duration: PROGRESS_INTERVAL_TIME_MS / 1000,
    });
  }
}

function resetProgressBar() {
  /**
   * @summary Resets the progress bar. Uses GSAP
   */

  const progressBarContainer = document.querySelector(
    ".custom-progress-bar__container"
  );
  const progressBar = document.querySelector(".custom-progress-bar");
  const progressBarText = document.querySelector(".custom-progress-bar__text");

  progressBarFinished = false;
  progressBarText.style.display = "none";
  progressBarContainer.style.boxShadow = "0 0 5px #516fe7;";
  gsap.to(progressBar, {
    x: `0%`,
    duration: 2,
  });
}

export function resetInfo() {
  /**
   * @summary Hide the results, progress and error elements, basically to
   * start with a blank slate again.
   */
  // Hide results and errors
  hide(document.getElementById("results"));
  hide(document.getElementById("added-to-queue"));
  hide(document.getElementById("queue-info"));
  hide(document.getElementById("gathering-links"));
  show(document.getElementById("gathering-pages"));

  document.getElementById("submit-btn").setAttribute("disabled", true);
  document.getElementById("error-msg").innerHTML = "";
  hide(document.getElementById("no-broken-links"));

  // Reset progress display
  hide(document.getElementById("progress-update"));
  hide(document.getElementById("progress-container"));
  hide(document.getElementById("preprogress-animation"));
  document.getElementById("pages-to-check").innerHTML = "?";
  document.getElementById("urls-to-check").innerHTML = "?";
  document.getElementById("checked-urls").innerHTML = 0;
  document.getElementById("skipped-urls").innerHTML = 0;
  document.getElementById("completion-time").innerHTML = "?";

  resetProgressBar();

  // Remove all rows from the table body
  let tbody = document.querySelector("#results-table tbody");
  while (tbody.firstChild) {
    tbody.removeChild(tbody.lastChild);
  }
}

export function updateUiResults(results) {
  /**
   * @summary handle displaying the results on the page
   * @param {Array} results the list of results
   */

  if (results == null) {
    return;
  }

  // Reset form
  document.querySelector(".needs-validation").classList.remove("was-validated");

  // Hide the estimation of the time left, since the task is finished.
  hide(document.getElementById("time-left"));

  // Enable submit button again
  document.getElementById("submit-btn").removeAttribute("disabled");
  // Hide the spinner
  hide(document.getElementById("preprogress-animation"));

  // if there are no results, show that there were no broken links found
  if (results.length == 0) {
    show(document.getElementById("no-broken-links"));
    return;
  }

  // repopulate table with received data
  let tbody = document.querySelector("#results-table tbody");
  results.forEach((result, index) => {
    // show tooltip for some status codes, since they can
    // result from the checker being blocked by the site, without
    // the link being actually broken
    const blockingCodes = [401, 403, 407, 503];
    let showTooltip = "";
    if (blockingCodes.includes(result.status_code)) {
      showTooltip = `
        <i class="tooltip-icon text-primary bi bi-question-circle" tabindex="0"
            aria-label="Informations" data-bs-toggle="tooltip" data-bs-placement="right"
            title="Un statut ${result.status_code} peut indiquer que le vérificateur a été bloqué pour des raisons d'authentification. Le lien peut toujours être disponible."></i>
        `;
    }

    // create the new row
    let htmlRowContent = `
        <td scope="row" class="align-middle">${index + 1}</td>
        <td class="align-middle"><a href="${result.page_url}">${
      result.page_title
    }</a></td>
        <td class="align-middle"><a href="${rewriteRelativeUrl(
          result.link_url
        )}">${result.link_title}</a></td>
        <td class="align-middle">${result.link_url}</td>
        <td class="align-middle">${result.status_code}${showTooltip}</td>`;

    // add a new row to the results table
    let row = document.createElement("tr");
    row.innerHTML = htmlRowContent;

    tbody.append(row);
  });

  // Sort the table if there are results
  if (results.length > 0) {
    new Tablesort(document.getElementById("results-table"), {
      descending: true,
    });
  }

  // Initialize tooltips
  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // show results table if it was hidden
  show(document.getElementById("results"));
}

export function onError(errorMessage) {
  /**
   * @summary Display an error message on the page
   * @param {String} errorMessage the error message
   */
  console.error(errorMessage);

  // stop requesting updates
  clearInterval(progressCheckingInterval);

  // show error message
  const errorMessageContainer = document.getElementById("error-msg");
  errorMessageContainer.innerHTML =
    "Une erreur s'est produite: " + errorMessage;
  show(errorMessageContainer);

  // enable submit button again
  document.getElementById("submit-btn").removeAttribute("disabled");
  hide(document.getElementById("progress-container"));
}
