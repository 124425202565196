import { store } from "./state";
import { rectifyURL } from "./utils";
import { resetInfo } from "./ui";
import { show } from "./utils";
import { submitForm } from "./api";

export function initForm() {
  /**
   * @summary Validates the form and trigger submitForm when the submit button
   *          is clicked
   * @see https://getbootstrap.com/docs/5.0/forms/validation/
   */

  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  document
    .querySelector("#submit-btn")
    .addEventListener("click", submit, false);
}

function submit(event) {
  /**
   * @summary Validates and submits the form data to the server for processing
   * @param {Event} event form submit event
   */
  event.preventDefault();
  event.stopPropagation();

  const form = document.querySelector(".needs-validation");

  // Add missing http scheme or www
  const urlTextInput = document.getElementById("url-input");
  urlTextInput.value = rectifyURL(urlTextInput.value);

  validateUrl();
  validateEmail();

  if (!form.checkValidity()) {
    return;
  }

  // Show that the form is valid
  form.classList.add("was-validated");

  // Disable submit button
  document.getElementById("submit-btn").setAttribute("disabled", "");

  // Prepare UI
  resetInfo();

  // Show spinner
  show(document.getElementById("progress-container"));
  show(document.getElementById("preprogress-animation"));
  show(document.getElementById("gathering-pages"));

  // Reset currentProgress
  store.update("currentProgress", {});

  // Retrieve form data
  let formData = getFormData();

  store.update("currentURL", formData.url);

  // Send form data to the server
  submitForm(formData);
}

function validateUrl() {
  const input = document.getElementById("url-input");
  const validityState = input.validity;

  // Show an appropriately localized descriptive error tooltip
  if (validityState.valueMissing || validityState.patternMismatch) {
    input.setCustomValidity("Veuillez entrer l'adresse d'un site UNIL");
  } else {
    input.setCustomValidity("");
  }

  input.reportValidity();
}

function validateEmail() {
  /**
   * @summary validate email address (uses bootstrap form validation, see
   *          form.html)
   */
  const input = document.getElementById("email-input");
  const validityState = input.validity;

  // Show an appropriately localized descriptive error tooltip
  if (validityState.valueMissing || validityState.patternMismatch) {
    input.setCustomValidity("Veuillez entrer une adresse email UNIL");
  } else {
    input.setCustomValidity("");
  }

  input.reportValidity();
}

function getFormData() {
  /**
   * @summary retrieves inputs from the form and package them in JSON.
   * @returns {Object} the structured form data
   */
  // Retrieve the data entered in the form and rectify it if needed
  const url = document.getElementById("url-input").value.trim();
  const email = document.getElementById("email-input").value.trim();

  // Get options
  const selectedVerificationScope = document.querySelector(
    `input[name='verification-scope']:checked`
  );
  const verificationScope = selectedVerificationScope.value;

  const selectedLinkType = document.querySelector(
    `input[name='links-type']:checked`
  );
  const checkScope = selectedLinkType.value;
  const checkInternal = checkScope.includes("internal");
  const checkExternal = checkScope.includes("external");

  const checkMedia = document.getElementById("check-media").checked;
  const flagRedirections = document.getElementById("flag-redirections").checked;
  const ignoreCache = document.getElementById("ignore-cache").checked;
  const checkOrphans = document.getElementById("check-orphans").checked;

  // Format data as an object for serialization
  const formData = {
    url: url,
    email: email,
    "verification-scope": verificationScope,
    options: {
      "check-external": checkExternal,
      "check-internal": checkInternal,
      "check-media": checkMedia,
      "flag-redirections": flagRedirections,
      "ignore-cache": ignoreCache,
      "check-orphans": checkOrphans,
    },
  };

  return formData;
}
