export const appState = {
  currentURL: null,
  currentProgress: {},
  currentResults: null,
};

export function updateProgressData(data) {
  // Adds new updateData to our appState.currentProgress, because the server
  // doesn't remember past updates sent to us, only the current state of the
  // task.

  if (data.job_finished) {
    // If the job is finished, we can update the progress to show completion
    appState.currentProgress.checked_urls =
      appState.currentProgress.urls_to_check;
    appState.currentProgress.estimated_time_remaining = 0;
  }
  if (data.queue_position != null) {
    appState.currentProgress.queue_position = data.queue_position;
  }
  if (data.pages_to_check) {
    appState.currentProgress.pages_to_check = data.pages_to_check;
  }
  if (data.urls_to_check) {
    appState.currentProgress.urls_to_check = data.urls_to_check;
  }
  if (data.skipped_urls) {
    appState.currentProgress.skipped_urls = data.skipped_urls;
  }
  if (data.checked_urls) {
    appState.currentProgress.checked_urls = data.checked_urls;
  }
  if (data.estimated_time_remaining) {
    appState.currentProgress.estimated_time_remaining =
      data.estimated_time_remaining;
  }
  if (data.link_checking_start_time) {
    appState.currentProgress.link_checking_start_time =
      data.link_checking_start_time;
  }
  if (data.finish_time) {
    appState.currentProgress.finish_time = data.finish_time;
    appState.currentProgress.completion_time = Math.round(
      appState.currentProgress.finish_time -
        appState.currentProgress.link_checking_start_time
    );
  }
  if (data.links_per_second) {
    appState.currentProgress.links_per_second = data.links_per_second;
  }
}
